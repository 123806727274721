import $ from 'jquery'
import Selectize from 'selectize'
import "selectize/dist/css/selectize.css"
import "selectize/dist/css/selectize.default.css"

// custom plugin
Selectize.define('enter_key_submit', function (_options) {
  var self = this

  this.onKeyDown = (function (_e) {
    var original = self.onKeyDown

    return function (e) {
      // this.items.length MIGHT change after event propagation.
      // We need the initial value as well. See next comment.
      var initialSelection = this.items.length
      original.apply(this, arguments)

      if (e.key === 'Enter'
          // Necessary because we don't want this to be triggered when an option is selected with Enter after pressing DOWN key to trigger the dropdown options
          && initialSelection && initialSelection === this.items.length
          && this.$control_input.val() === '') {
        self.trigger('submit')
      }
    }
  })()
})

document.addEventListener("turbo:load", function() {
  // Suggest tags
  $('[data-suggest-tags]').selectize({
    plugins: ['enter_key_submit'],
    onInitialize: function () {
      this.on('submit', function () {
        this.$input.closest('form').submit()
      }, this)
    },
    valueField: 'title',
    labelField: 'value',
    searchField: 'title',
    delimiter: ',',
    persist: false,
    options: [],
    create: function(input) {
      return {
        value: input,
        title: input,
        color: "#666666"
      }
    },
    render: {
      item: function(item, escape) {
        return '<div style="background-color: ' + item.color + ';">' +
          '<span class="text-white">' + escape(item.title) + '</span>' +
        '</div>';
      },
      option: function(item, escape) {
        return '<div>' +
          '<span class="text-white rounded inline-block px-1 py-0.5 mx-1 my-0.5" style="background-color: ' + item.color + ';">' + escape(item.title) + '</span>' +
        '</div>';
      }
    },
    load: function(query, callback) {
      $.ajax({
        url: '/tags.json',
        type: 'GET',
        dataType: 'json',
        data: {
          search: query,
        },
        error: function() {
          callback()
        },
        success: function(response) {
          callback(response.tags)
        }
      })
    }
  })
})
