import Rails from "@rails/ujs"
import { observeElementInViewport } from 'observe-element-in-viewport'

document.addEventListener("turbo:load", function() {
  const targets = document.querySelectorAll('.ad')

  targets.forEach(target => {
    const inHandler = (_entry, _unobserve, targetEl) => {
      if (targetEl.dataset.printed !== "true") {
        targetEl.dataset.printed = "true"
        Rails.ajax({
          type: "GET",
          url: "/ads/" + targetEl.dataset.identifier
        })
      }
    }
    observeElementInViewport(target, inHandler)
  })
})
